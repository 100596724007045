import numberCurrency from './numberCurrency';

export function formatFidelityType(value, type) {
  if (type === 'cb' || !type) {
    return numberCurrency(value, true, 2);
  }
  if (type === 'pt') {
    return numberCurrency(value, false, 0) + ' pts';
  }
  throw new Error(`Tipo desconhecido: ${type}`);
}
