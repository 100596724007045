/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Label, FormGroup } from 'reactstrap';
import Select from 'react-select';
import { GET_LIST_STORE } from '../../../store/queries/store';
import { useLazyQuery } from '@apollo/client';

function SelectStoreCustomer({
  ismulti,
  disable,
  value,
  parentCallback,
  label,
  customer,
  width = '100%',
  height = '100%'
}) {
  const [getStore, { data, error, called }] = useLazyQuery(GET_LIST_STORE);
  const [stores, setStores] = useState([]);

  useEffect(() => {
    if (customer) {
      getStore({
        variables: {
          customer: customer.value
        }
      });
    }
  }, [customer]);

  useEffect(() => {
    if (data) {
      if (data.storesCustomer) {
        let listStore = [];
        const itemstores = data.storesCustomer.docs;
        itemstores.map(item => {
          const data = {
            value: item._id,
            label: item.name
          };
          listStore.push(data);
        });
        setStores(listStore);
      }
    }
  }, [data]);
  return (
    <FormGroup style={{ width, height }}>
      <Label for='SelectStoreCustomer'>{label}</Label>
      <Select
        isMulti={ismulti}
        closeMenuOnSelect={ismulti ? false : true}
        onChange={e => parentCallback(e)}
        isSearchable={true}
        isDisabled={disable}
        value={value}
        placeholder={'Selecione uma empresa'}
        options={stores}
        noOptionsMessage={() => 'Não há empresas'}
        menuPortalTarget={document.body}
        styles={{
          backgroundColor: 'blue',
          menu: provided => ({
            ...provided,
            zIndex: 9999,
            position: 'sticky',
            overflow: 'hidden'
          })
        }}
      />
    </FormGroup>
  );
}
export default SelectStoreCustomer;
