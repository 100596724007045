import { gql } from '@apollo/client';
export const GET_PRODUCT = gql`
  query getProduct($_id: ID!) {
    product(_id: $_id) {
      _id
      name
      image
      images
      unit
      description
      usage
      pointsRequired
      prices {
        active
        _id
        hiddenPrice
        value
        valueDiscount
        segment
        stock

        validateStock
        gasstation {
          _id
          name
        }
        store {
          _id
          name
        }
      }
      price
      priceDes
      ref
      destaque
      categories {
        _id
        name
        type
      }
      gasstation {
        _id
        name
      }
      store {
        _id
        name
      }
    }
  }
`;

export const ADD_PRODUCT = gql`
  mutation addProduct(
    $name: String
    $image: String
    $store: [ID]
    $gasstation: [ID]
    $categories: [ID]
    $images: [String]
    $ref: String
    $price: String
    $priceDes: String
    $description: String
    $customer: ID
    $unit: String
    $destaque: Boolean
    $usage: String
    $pointsRequired: Float
  ) {
    addProduct(
      name: $name
      image: $image
      store: $store
      gasstation: $gasstation
      categories: $categories
      images: $images
      ref: $ref
      customer: $customer
      price: $price
      priceDes: $priceDes
      description: $description
      unit: $unit
      destaque: $destaque
      usage: $usage
      pointsRequired: $pointsRequired
    ) {
      _id
      name
    }
  }
`;

export const INACTIVATE_PRODUCT = gql`
  mutation inactivateProduct($id: [ID!]) {
    inactivateProduct(_id: $id) {
      success
      message
    }
  }
`;

export const ACTIVATE_PRODUCT = gql`
  mutation activateProduct($id: [ID!]) {
    activateProduct(_id: $id) {
      success
      message
    }
  }
`;

export const PAGINATE_PRODUCTS = gql`
  query paginateProducts(
    $page: Int!
    $perPage: Int!
    $orderBy: String
    $orderDirection: ORDER_DIRECTION
    $search: String
    $searchFields: [String]
    $customer: ID!
    $active: Boolean
  ) {
    productsPaginate(
      page: $page
      perPage: $perPage
      orderBy: $orderBy
      orderDirection: $orderDirection
      search: $search
      searchFields: $searchFields
      active: $active

      customer: $customer
    ) {
      totalDocs
      page
      totalPages
      docs {
        _id
        image
        productName
        description
        categories
        ref
        price
        priceDes
        destaque
        active
        createdAt
        updatedAt
      }
    }
  }
`;

export const UPDATE_PRODUCT = gql`
  mutation updateProduct(
    $_id: ID
    $name: String
    $image: String
    $store: [ID]
    $gasstation: [ID]
    $categories: [ID]
    $images: [String]
    $ref: String
    $price: String
    $priceDes: String
    $description: String
    $customer: ID
    $unit: String
    $destaque: Boolean
    $usage: String
    $pointsRequired: Float
  ) {
    updateProduct(
      _id: $_id
      name: $name
      image: $image
      store: $store
      gasstation: $gasstation
      categories: $categories
      images: $images
      ref: $ref
      customer: $customer
      price: $price
      priceDes: $priceDes
      description: $description
      unit: $unit
      destaque: $destaque
      usage: $usage
      pointsRequired: $pointsRequired
    ) {
      success
    }
  }
`;
const InputPriceProduct = {
  product: String,
  store: [String],
  gasstation: [String],
  customer: String,
  user: String,
  hiddenPrice: Boolean,
  userLastUpdate: String,
  segment: String,
  value: Number,
  valueDiscount: Number,
  createdAt: Date,
  updatedAt: Date,
  active: Boolean,
  validateStock: Boolean
};

export const ADD_PRICE_PRODUCT = gql`
  mutation addPriceProduct($inputs: [InputPriceProduct]) {
    addPriceProduct(inputs: $inputs) {
      success
      message
    }
  }
`;

export const UPDATE_PRICE_PRODUCT = gql`
  mutation updatePriceProduct(
    $_id: ID!
    $product: ID
    $store: [ID]
    $gasstation: [ID]
    $customer: ID!
    $user: ID!
    $segment: String!
    $value: Float!
    $active: Boolean
    $valueDiscount: Float
  ) {
    updatePriceProduct(
      _id: $_id
      product: $product
      store: $store
      gasstation: $gasstation
      customer: $customer
      segment: $segment
      value: $value
      user: $user
      active: $active
      valueDiscount: $valueDiscount
    ) {
      success
      message
    }
  }
`;
