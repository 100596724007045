/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Label, FormGroup, Input } from 'reactstrap';
import { getLoggedInUser } from '../../../helpers/authUtils';
import { connect } from 'react-redux';
import { Creators as storeActions } from '../../../store/ducks/store';
import { bindActionCreators } from 'redux';
import Select from 'react-select';

function SelectStore({
  user,
  store,
  getStores,
  ismulti,
  disable,
  value,
  parentCallback,
  label,
  getCustomers,
  customer
}) {
  const [stores, setStores] = useState([]);

  useEffect(() => {
    if (!store.error) {
      if (!store.success) {
        let listStore = [];
        store.map(item => {
          const data = {
            value: item._id,
            label: item.name
          };
          listStore.push(data);
        });
        setStores(listStore);
      }
    }
  }, [store]);

  useEffect(() => {
    getStores(user.customer, true);
  }, []);

  useEffect(() => [customer]);

  return (
    <FormGroup>
      <Label for='exampleSelect'>{label}</Label>
      <Select
        isMulti={ismulti}
        closeMenuOnSelect={ismulti ? false : true}
        onChange={e => parentCallback(e)}
        isSearchable={true}
        isDisabled={disable}
        value={value}
        placeholder={label}
        options={stores}
        noOptionsMessage={() => 'Não há lojas'}
        menuPortalTarget={document.body}
        styles={{
          backgroundColor: 'blue',
          menu: provided => ({ ...provided, zIndex: 900 })
        }}
      />
    </FormGroup>
  );
}

const mapStateToProps = state => ({ store: state.store, user: state.user });
const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...storeActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SelectStore);
