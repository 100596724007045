import React, { Component, useEffect, useState } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import { getLoggedInUser } from '../../../helpers/authUtils';
import Select from 'react-select';
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_PRODUCTS_CUSTOMER } from '../../../store/queries/productgas';
import lodash from 'lodash';
import { GET_CATEGORIES_CUSTOMER } from '../../../store/queries/category';
import { connect } from 'react-redux';
import { GroupContainer, GroupBadge, CategoryPicture } from '../styles';
import { PAGINATE_PRODUCTS } from '../../../store/queries/products';

const ALL_OPTION = {
  value: null,
  label: 'Todos os Produtos'
};

const formatGroupLabel = data => (
  <GroupContainer>
    <span>{data.label}</span>
    <GroupBadge>{data.options.length}</GroupBadge>
  </GroupContainer>
);

/**
 *
 * @param {Object} props
 * @param {"PRODUCT" | "SERVICE"} props.type
 * @returns
 */
function SelectProductPoints({
  user,
  ismulti,
  disable,
  allOption = false,
  type,
  value,
  parentCallback,
  label = 'Selecionar os Produtos',
  placeholder
}) {
  const { data, loading, error } = useQuery(PAGINATE_PRODUCTS, {
    variables: {
      customer: user.customer,
      page: 1,
      perPage: 1000
    },
    fetchPolicy: 'cache-and-network'
  });

  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (data && data.productsPaginate) {
      const categories = [];

      if (allOption) {
        categories.push(ALL_OPTION);
      }

      data.productsPaginate.docs.forEach(product => {
        categories.push({
          label: product.productName,
          value: product._id
        });
      });

      setOptions(categories);
    }
  }, [data]);

  return (
    <FormGroup>
      <Label for='exampleSelect'>{label}</Label>
      <Select
        isMulti={ismulti}
        closeMenuOnSelect={ismulti ? false : true}
        onChange={e => parentCallback(e)}
        value={value}
        isDisabled={disable}
        isSearchable
        placeholder={placeholder}
        options={options}
        isLoading={loading}
        noOptionsMessage={() => 'Não há produtos'}
        styles={{
          backgroundColor: 'blue',
          menu: provided => ({ ...provided, zIndex: 900 })
        }}
        formatGroupLabel={formatGroupLabel}
      />
    </FormGroup>
  );
}

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(SelectProductPoints);
