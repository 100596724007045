import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import {
  Card,
  CardBody,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Form
} from 'reactstrap';
import { GET_RESCUE_PRODUCTS } from '../../../store/queries/cashbackReport';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import '../../../assets/css/override.css';
import '../../../assets/css/customDatePickerWidth.css';
import { ReportButtons } from '../../../components/Form/Button';
import MaterialTable from '../../../components/Table/MaterialTable';
import SelectEmpresa from '../../../components/Select/SelectEmpresa';
import { Required } from '../../marketing/clientsSelectionV2/styles';
import { SelectBenefit } from '../../../components/Select/SelectFidelityMethod';
import SelectStore from '../../../components/Select/selectStore';
import SelectStoreCustomer from '../../../components/Select/SelectStoreCustomer';
import { formatDateWithSlash } from '../../../helpers/date';
import { formatFidelityType } from '../../../helpers/formatFidelityType';
import SelectProdutos from '../../../components/Select/SelectProdutos';
import SelectProductGas from '../../../components/Select/SelectProductGas';
import SelectProductPoints from '../../../components/Select/SelectProductPoints';
import DataTable from '../../../components/Table';

const columnHead = [
  {
    title: 'Empresa',
    field: 'store',
    width: 300,
    cellStyle: { textAlign: 'left' },
    headerStyle: { textAlign: 'left' }
  },
  {
    title: 'Produto',
    field: 'product',
    cellStyle: { textAlign: 'left' },
    headerStyle: { textAlign: 'left' }
  },
  {
    title: 'Quantidade',
    field: 'quantity',
    cellStyle: { textAlign: 'right' },
    headerStyle: { textAlign: 'right' }
  },
  {
    title: 'Pontos',
    field: 'resumeValues',
    cellStyle: { textAlign: 'right' },
    headerStyle: { textAlign: 'right' }
  }
];

const formattedRows = data => {
  return data.map(item => ({
    totalRow: false,
    store: item.store,
    product: item.product,
    quantity: item.quantity,
    resumeValues: formatFidelityType(item.resumeValues, 'pt')
  }));
};

function RescueProductReport({ user }) {
  const [startDate, setStartDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [selectedProdutos, setSelectedProdutos] = useState([]);

  const [rows, setRows] = useState([]);
  const [selectedEmpresas, setSelectedEmpresas] = useState([]);
  const [getReport, { data, loading }] = useLazyQuery(GET_RESCUE_PRODUCTS, {
    fetchPolicy: 'network-only'
  });

  const handleConfirm = useCallback(async () => {
    const variables = {
      customer: user.customer,
      store: selectedEmpresas.map(item => item.value) || null,
      products: selectedProdutos.map(item => item.value) || null,
      startDate,
      endDate
    };

    const { data } = await getReport({ variables });

    if (data.getRescueProduct.length > 0) {
      const formatData = formattedRows(data.getRescueProduct[0].details);
      const rowsWithTotals = [
        ...formatData,
        {
          store: 'Total',
          product: '',
          quantity: data.getRescueProduct[0].totals.quantity,
          resumeValues: formatFidelityType(
            data.getRescueProduct[0].totals.resumeValues,
            'pt'
          ),
          totalRow: true
        }
      ];

      setRows(rowsWithTotals);
    }
  }, [
    endDate,
    getReport,
    selectedEmpresas,
    selectedProdutos,
    startDate,
    user.customer
  ]);
  const setCancel = () => {};

  useEffect(() => {
    const getReportData = async () => {
      if (user) {
        const { data } = await getReport({
          variables: {
            customer: user.customer,
            startDate,
            endDate,
            store: null
          }
        });
        if (data.getRescueProduct.length > 0) {
          const formatData = formattedRows(data.getRescueProduct[0].details);
          const rowsWithTotals = [
            ...formatData,
            {
              store: 'Total',
              product: '',
              quantity: data.getRescueProduct[0].totals.quantity,
              resumeValues: formatFidelityType(
                data.getRescueProduct[0].totals.resumeValues,
                'pt'
              ),
              totalRow: true
            }
          ];

          setRows(rowsWithTotals);
        }
      }
    };
    getReportData();
  }, []);

  return (
    <div className=''>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Form>
                <Row>
                  <Col lg={3}>
                    <FormGroup>
                      <Label for='dataInicio'>Data Inicial* </Label>
                      <Input
                        value={startDate}
                        type='date'
                        name='dataInicio'
                        onChange={e => setStartDate(e.target.value)}
                        id='dataInicio'
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={3}>
                    <FormGroup>
                      <Label for='dataFim'>Data Final*</Label>
                      <Input
                        value={endDate}
                        type='date'
                        name='dataFim'
                        onChange={e => setEndDate(e.target.value)}
                        id='dataFim'
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={3}>
                    <SelectStoreCustomer
                      label='Loja'
                      ismulti
                      customer={{ value: user.customer }}
                      parentCallback={setSelectedEmpresas}
                      value={selectedEmpresas}
                      placeholder='Selecione a(s) empresa(s)...'
                    />
                  </Col>
                  <Col lg={3}>
                    <SelectProductPoints
                      label='Produto'
                      ismulti
                      customer={{ value: user.customer }}
                      parentCallback={setSelectedProdutos}
                      value={selectedProdutos}
                      placeholder='Selecione o(s) produto(s)...'
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg={8}>
                    <ReportButtons
                      onCancel={setCancel}
                      onConfirm={() => handleConfirm()}
                    />
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Card>
        <CardBody>
          <DataTable
            rows={rows}
            rowHead={columnHead}
            title={'Relatório de Produtos Resgatados'}
            loading={loading}
            searchInput
            showPagination={false}
            showTitle={false}
            showShadow={false}
            showExportButton
          />
        </CardBody>
      </Card>
    </div>
  );
}

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(RescueProductReport);
