import Select from 'react-select';
import { FormGroup, Label } from 'reactstrap';

/**
 *
 * @param {{setFidelityMethod: (fidelityMethod: { label: 'Todos', value: 'both' }|{ label: 'Cashback', value: 'cb' }|{ label: 'Pontos', value: 'pt' }) =>void,
 * disable: boolean,
 * fidelityMethod: { label: 'Todos', value: 'both' }|{ label: 'Cashback', value: 'cb' }|{ label: 'Pontos', value: 'pt' }
 * }} props
 */
export function SelectBenefit({
  setFidelityMethod,
  disable = false,
  both = true,
  fidelityMethod
}) {
  const bothOption = { label: 'Todos', value: 'both' };

  const methodOptions = [
    { label: 'Cashback', value: 'cb' },
    { label: 'Pontos', value: 'pt' }
  ];

  if (both) {
    methodOptions.push(bothOption);
  }

  return (
    <FormGroup>
      <Label for='benefitType'>Tipo de Benefício</Label>
      <Select
        id='benefitType'
        onChange={setFidelityMethod}
        isSearchable={false}
        isDisabled={disable}
        styles={{
          backgroundColor: 'blue',
          menu: provided => ({ ...provided, zIndex: 900 })
        }}
        value={fidelityMethod}
        options={methodOptions}
      />
    </FormGroup>
  );
}
