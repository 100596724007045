import { gql } from '@apollo/client';

export const SAVE_CASHBACK_REPORT_FOR_MONTH = gql`
  mutation saveCashbackReport($customer: ID!, $user: ID!, $months: [String]) {
    saveCashbackReportsForMonth(
      customer: $customer
      user: $user
      months: $months
    ) {
      _id
      currentMonth
      lastMonth
      currentMonthTotalA
      lastMonthTotalA
      balanceTotalA
      currentMonthTotalR
      totalToPay
      percentageParticipation
      nextMonthRemainingValue
      createdAt
      updatedAt
      active
      reconciled
      cashbackReportDetails {
        currentMonth
        lastMonth
        currentMonthTotalA
        lastMonthTotalA
        balanceTotalA
        currentMonthTotalR
        totalToPay
        percentageParticipation
        nextMonthRemainingValue
      }
    }
  }
`;

export const CALCULATE_CASHBACK_REPORT_FOR_MONTH = gql`
  query calculateCashbackReport($customer: ID!, $month: String!) {
    calculateCashbackReportsForMonth(customer: $customer, month: $month) {
      currentMonth
      lastMonth
      currentMonthTotalA
      lastMonthTotalA
      balanceTotalA
      currentMonthTotalR
      totalToPay
      percentageParticipation
      nextMonthRemainingValue
      createdAt
      updatedAt
      active
      reconciled
      cashbackReportDetails {
        currentMonth
        lastMonth
        currentMonthTotalA
        lastMonthTotalA
        balanceTotalA
        currentMonthTotalR
        totalToPay
        percentageParticipation
        nextMonthRemainingValue
        gasstation {
          _id
          name
        }
        store {
          _id
          name
        }
      }
    }
  }
`;

export const GET_CASHBACK_REPORTS_BY_MONTHS = gql`
  query getCashbackReportsByMonth($customer: ID!, $months: [String]) {
    cashbackReportsByMonth(customer: $customer, months: $months) {
      currentMonth
      lastMonth
      currentMonthTotalA
      lastMonthTotalA
      balanceTotalA
      currentMonthTotalR
      totalToPay
      percentageParticipation
      nextMonthRemainingValue
      createdAt
      updatedAt
      active
      reconciled
      cashbackReportDetails {
        currentMonth
        lastMonth
        currentMonthTotalA
        lastMonthTotalA
        balanceTotalA
        currentMonthTotalR
        totalToPay
        percentageParticipation
        nextMonthRemainingValue
        gasstation {
          _id
          name
        }
        store {
          _id
          name
        }
      }
    }
  }
`;

export const GET_CASHBACK_REPORTS_BY_MONTHS_PAGINATED = gql`
  query getCashbackReportsByMonthPaginated(
    $customer: ID!
    $months: [String]
    $page: Int
  ) {
    cashbackReportsByMonthPaginate(
      customer: $customer
      months: $months
      page: $page
    ) {
      totalDocs
      totalPages
      page
      docs {
        currentMonth
        lastMonth
        currentMonthTotalA
        lastMonthTotalA
        balanceTotalA
        currentMonthTotalR
        totalToPay
        percentageParticipation
        nextMonthRemainingValue
        createdAt
        updatedAt
        active
        reconciled
        cashbackReportDetails {
          currentMonth
          lastMonth
          currentMonthTotalA
          lastMonthTotalA
          balanceTotalA
          currentMonthTotalR
          totalToPay
          percentageParticipation
          nextMonthRemainingValue
          gasstation {
            _id
            name
          }
          store {
            _id
            name
          }
        }
      }
    }
  }
`;

export const GET_RESCUE_PRODUCTS = gql`
  query GetRescueProduct(
    $customer: ID!
    $store: [ID]
    $startDate: String
    $endDate: String
    $products: [ID]
  ) {
    getRescueProduct(
      customer: $customer
      store: $store
      startDate: $startDate
      endDate: $endDate
      products: $products
    ) {
      details {
        store
        product
        quantity
        resumeValues
      }
      totals {
        quantity
        resumeValues
      }
    }
  }
`;

export const GET_CASHBACK_REPORT_LAST_ALLOWED_MONTH = gql`
  query getCashbackReportLastAllowedMonth($customer: ID!) {
    cashbackReportLastAllowedMonth(customer: $customer)
  }
`;
