import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MaterialTable, { MTableToolbar } from 'material-table';
import lodash from 'lodash';
import { Row, Col } from 'reactstrap';

import {
  ActivateItem,
  EditValueItem,
  InactivateItem
} from './ActionItems/index';

export default function DataTable({
  rows = [],
  rowHead = [],
  title,
  grouping,
  rowClick,
  titleNoData,
  editValuesLabel,
  searchInput,
  showPagination,
  showTitle,
  rightTitle,
  showShadow,
  showExportButton,
  loading,
  pageSizeOptions = [10, 20, 50, 100, 200, 500, 1000],
  rowStyle,
  selectable = false,
  onOrderChange,
  showToolbar,
  filterByStatus,
  filterByStatusValue,
  callbackFunctionInativar,
  callbackFunctionAtivar,
  callbackFunctionEditValues,
  onSelectionChange,
  doubleHorizontalScroll = false,
  fixedColumns,
  tableLayout = 'auto',
  options
}) {
  const [pageSize, setPageSize] = useState(10);

  const [activeCount, setActiveCount] = useState();
  const [inactiveCount, setInactiveCount] = useState();

  function functionSelectionChange(data) {
    setActiveCount(lodash.filter(data, item => item.active === 'Sim').length);
    setInactiveCount(lodash.filter(data, item => item.active === 'Não').length);
    if (onSelectionChange) {
      onSelectionChange(data);
    }
  }

  function setRows(rows) {
    if (filterByStatus) {
      if (filterByStatusValue === 'ativos') {
        return rows.filter(x => x.active === 'Sim' || x.active == true);
      } else if (filterByStatusValue === 'inativos') {
        return rows.filter(x => x.active === 'Não' || x.active == false);
      } else {
        return rows;
      }
    } else {
      return rows;
    }
  }

  return (
    <div style={{ maxWidth: '100%' }}>
      <MaterialTable
        style={{
          boxShadow: showShadow ? 'set' : 'unset',
          overflow: 'inherit',
          zIndex: 0,
          fontFamily: 'Nunito, sans-serif'
        }}
        isLoading={loading || false}
        onRowClick={rowClick}
        onChangeRowsPerPage={setPageSize}
        onSelectionChange={data => functionSelectionChange(data)}
        onOrderChange={onOrderChange}
        columns={rowHead}
        data={setRows(rows)}
        title={title}
        //parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
        options={{
          fixedColumns: fixedColumns,
          doubleHorizontalScroll: doubleHorizontalScroll,
          exportButton: showExportButton,
          exportAllData: showExportButton,
          showTitle: showTitle || false,
          detailPanelColumnAlignment: 'left',
          searchFieldAlignment: 'left',
          tableLayout: tableLayout,
          headerStyle: {
            textAlign: 'center'
          },
          paging: showPagination,
          pageSize,
          pageSizeOptions,
          emptyRowsWhenPaging: false,
          padding: 'dense',
          search: searchInput,
          rowStyle: (row, index) => {
            return (
              rowStyle || {
                backgroundColor: index % 2 ? '#FFFFFF' : '# ebeff2',
                '&:hover': { backgroundColor: '#ebeff2' },
                color:
                  row.active === 'Não'
                    ? 'red'
                    : row.disabled === true
                      ? '#AAA'
                      : 'black',
                fontWeight: row.totalRow ? 'bolder' : 'normal'
              }
            );
          },
          grouping: grouping !== null ? grouping : true,
          selection: selectable,
          showTitle: title ? true : false,
          toolbar: showToolbar || true
        }}
        actions={[
          selectable && callbackFunctionEditValues
            ? {
                tooltip: editValuesLabel || 'Editar registros selecionados',
                icon: () => <EditValueItem />,
                onClick: (evt, data) => {
                  callbackFunctionEditValues(data);
                }
              }
            : null,
          selectable && activeCount > 0 && inactiveCount === 0
            ? {
                tooltip: 'Inativar registros selecionados',
                icon: () => <InactivateItem />,
                onClick: (evt, data) => {
                  callbackFunctionInativar(data);
                }
              }
            : null,
          selectable && inactiveCount > 0 && activeCount === 0
            ? {
                tooltip: 'Ativar registros selecionados',
                icon: () => <ActivateItem />,
                onClick: (evt, data) => {
                  callbackFunctionAtivar(data);
                }
              }
            : null
        ]}
        localization={{
          pagination: {
            labelRowsPerPage: 'Linhas por pagina:',
            labelRowsSelect: 'linhas',
            labelDisplayedRows: '{from}-{to} de {count}',
            firstAriaLabel: 'Primeira Página',
            firstTooltip: 'Primeira Página',
            previousAriaLabel: 'Página Anterior',
            previousTooltip: 'Página Anterior',
            nextAriaLabel: 'Pŕoxima Página',
            nextTooltip: 'Pŕoxima Página',
            lastAriaLabel: 'Última Página',
            lastTooltip: 'Última Página'
          },
          toolbar: {
            searchPlaceholder: 'Pesquisar',
            searchTooltip: 'Pesquisar',
            nRowsSelected: '{0} linha(s) selecionada(s)',
            addRemoveColumns: 'Adicionar ou remover colunas',
            showColumnsTitle: 'Exibir colunas',
            showColumnsAriaLabel: 'Exibir colunas',
            exportTitle: 'Exportar',
            exportAriaLabel: 'Exportar',
            exportName: 'Exportar para .CSV'
          },
          grouping: {
            groupedBy: 'Agrupado por:',
            placeholder: 'Arraste os cabeçalhos aqui para agrupar por:'
          },
          body: {
            emptyDataSourceMessage: titleNoData || 'Nenhum registro encontrado',
            filterRow: {
              filterTooltip: 'Filtrar'
            }
          }
        }}
        components={{
          Toolbar: props => {
            return (
              <>
                <Row>
                  {showToolbar !== false && (
                    <Col>
                      <MTableToolbar {...props} />
                    </Col>
                  )}
                  {rightTitle ? (
                    <Col md={4} sm={12}>
                      {rightTitle}
                    </Col>
                  ) : null}
                </Row>
                {grouping === false && (
                  <hr style={{ borderColor: '#E0E0E0' }} />
                )}
              </>
            );
          }
        }}
      />
    </div>
  );
}

DataTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  rowHead: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string.isRequired,
  rowClick: PropTypes.func,
  titleNoData: PropTypes.string,
  searchInput: PropTypes.bool,
  loading: PropTypes.bool,
  showPagination: PropTypes.bool,
  showTitle: PropTypes.bool,
  showShadow: PropTypes.bool,
  showExportButton: PropTypes.bool,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.object)
};

DataTable.defaultProps = {
  rowClick: null,
  searchInput: false,
  loading: false,
  showPagination: true,
  showTitle: true,
  showShadow: false,
  showExportButton: false,
  titleNoData: 'Nenhum registro encontrado'
};
