/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */

import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { formatDateWithSlashAndHours } from '../../helpers/date';
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import { ReportButtons } from '../../components/Form/Button';
import MaterialTable from '../../components/Table/MaterialTable';
import { useTheme } from 'styled-components';
import { useLazyQuery } from '@apollo/client';
import {
  LIST_DELETED_CLIENTS,
  REACTIVATE_CLIENT
} from '../../store/queries/clients';
import { ActivateItem } from '../../components/Table/ActionItems';
import toast from '../../helpers/toast';

const voucherDateFormat = 'DD/MM/YYYY [às] HH:mm:ss';

function createData(id, name, email, dateExclusao, motivo, exec) {
  return {
    id,
    name,
    email,
    dateExclusao,
    motivo,
    exec
  };
}

const columnHeadVoucher = [
  {
    title: 'Nome',
    field: 'name',
    cellStyle: {
      textAlign: 'left'
    },
    headerStyle: {
      textAlign: 'left'
    }
  },
  {
    title: 'Email',
    field: 'email',
    cellStyle: {
      textAlign: 'left'
    },
    headerStyle: {
      textAlign: 'left'
    }
  },
  {
    title: 'Data de Exclusão',
    field: 'dateExclusao',
    cellStyle: {
      textAlign: 'left'
    },
    headerStyle: {
      textAlign: 'left'
    }
  },
  {
    title: 'Razão da Exclusão',
    field: 'motivo',
    sorting: false,
    cellStyle: {
      textAlign: 'left'
    },
    headerStyle: {
      textAlign: 'left'
    }
  },
  {
    title: 'Ações',
    field: 'exec',
    cellStyle: {
      textAlign: 'right'
    },
    headerStyle: {
      padding: 0,
      textAlign: 'right'
    }
  }
];

const SORTABLE_COLUMNS = ['name', 'email', 'dateExclusao', 'motivo'];

const FILTERABLE_COLUMNS = ['name', 'email', 'dateExclusao', 'motivo'];

function ReativarClientes({ user }) {
  const theme = useTheme();

  const [cliente, setCliente] = useState(null);
  const [listDataTable, setListDataTable] = useState([]);
  const [email, setEmail] = useState('');
  const [dateExclusao, setdateExclusao] = useState(new Date());

  const [showModal, setShowModal] = useState(false);
  const [infoCliente, setinfoCliente] = useState();
  const [listDeletedClients, { loading }] = useLazyQuery(LIST_DELETED_CLIENTS);
  const [reativarCliente, { loading: loadingReativarCliente }] =
    useLazyQuery(REACTIVATE_CLIENT);

  const setCancel = () => {
    setEmail('');
    setdateExclusao(new Date());
  };

  function activateAlert(item) {
    setShowModal(true);
    setinfoCliente(item);
  }

  const fetchData = useCallback(async () => {
    const { data } = await listDeletedClients({
      variables: { customer: user.customer, dateExclusao, email }
    });

    const formatData = data.listDeletedClients.map(item => {
      return createData(
        item._id,
        item.nameOld,
        item.emailOld,
        formatDateWithSlashAndHours(item.dateExclusao),
        item.motivo,
        <ActivateItem onClick={() => activateAlert(item)} />
      );
    });
    setListDataTable(formatData);
  }, [dateExclusao, email, listDeletedClients, user.customer]);

  useEffect(() => {
    fetchData();
  }, []);

  const handleConfirm = useCallback(
    async _id => {
      try {
        const { data } = await reativarCliente({
          variables: { _id }
        });
        toast.success(data.reactivatedClient.message);
        setShowModal(false);
      } catch (error) {
        toast.error('Erro ao reativar cliente');
      }
    },
    [reativarCliente]
  );

  return (
    <div className=''>
      <Modal
        size='md'
        isOpen={showModal}
        toggle={() => setShowModal(!showModal)}
      >
        <ModalHeader toggle={() => setShowModal(!showModal)} charcCode='X'>
          Reativar Cliente
        </ModalHeader>
        <ModalBody>
          {infoCliente && (
            <h5>Deseja realmente reativar o cliente: {infoCliente.nameOld}?</h5>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            style={{ width: 120 }}
            color='danger'
            onClick={() => setShowModal(false)}
          >
            Cancelar
          </Button>
          <Button
            style={{ width: 120 }}
            color='success'
            onClick={() => handleConfirm(infoCliente._id)}
          >
            Confirmar
          </Button>
        </ModalFooter>
      </Modal>

      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Form>
                <Row>
                  <Col lg={4}>
                    <FormGroup>
                      <Label for='email'>Data de Exclusão</Label>
                      <Input
                        type='date'
                        placeholder='cibus@cibus.com.br'
                        value={dateExclusao}
                        onChange={e => setdateExclusao(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={4}>
                    <FormGroup>
                      <Label for='email'>Email do Cliente</Label>
                      <Input
                        type='email'
                        placeholder='cibus@cibus.com.br'
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col lg={8}>
                    <ReportButtons
                      onCancel={setCancel}
                      onConfirm={() => fetchData()}
                    />
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <>
        <Card>
          <CardBody>
            <MaterialTable
              sortableColumns={SORTABLE_COLUMNS}
              filterableColumns={FILTERABLE_COLUMNS}
              title={' Clientes Excluídos'}
              data={listDataTable}
              columns={columnHeadVoucher}
              options={{
                search: true,
                exportButton: false
              }}
              loading={loading}
            />
          </CardBody>
        </Card>
      </>
    </div>
  );
}

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(ReativarClientes);
