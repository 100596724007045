/**
 * Helper que formata um valor numérico em uma representação em string no formato brasileiro. A string pode ter o símbolo de moeda ou não.
 * @param {number} value valor a ser convertido
 * @param {boolean} symbol Valor deve ou não vir acompanhado do simbolo de moeda R$
 * @param {number} decimal quantidade de casas decimais. padrão 2
 * @param {boolean} showPrefix parametro inutilizado, não é necessário preencher
 * @returns {string} valor numerico transformado em valor monetário
 */
export default function numberCurrency(
  value,
  symbol = false,
  decimal = 2,
  showPrefix = false
) {
  if (symbol === true) {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      maximumFractionDigits: decimal
    }).format(value);
  }
  return new Intl.NumberFormat('pt-BR', {
    style: 'decimal',
    maximumFractionDigits: decimal,
    minimumFractionDigits: decimal
  }).format(value);
}
export function numberCurrencyToFloat(value, symbol = ',') {
  try {
    var newVal = value.toString();
    newVal = parseFloat(newVal.replace(/[^0-9\,-]+/g, '').replace(symbol, '.'));
    return newVal;
  } catch (error) {
    return undefined;
  }
}
export const toSupplyPrice = value => {
  return Number(Number(value).toFixed(2));
};
